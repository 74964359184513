//export const baseUrl="http://127.0.0.1:8000/api/";
 //export const baseUrl="http://apiwaimsc.cloudliveshop.com/api/";
export const baseUrl="https://apigucheng.jizhikaifa.com/api/";
export const api = {
    "checkUserLogin" : baseUrl + "Admin/check_user_login", // 验证是否已经登录
    "login" : baseUrl + "Admin/login", // 登录
    "logout" : baseUrl + "Admin/logout", // 退出账号 注销
    
    'getUserList' : baseUrl + "Admin/users/index",

    // 获取统计数据
    'adminGetStatistics' : baseUrl + "Admin/index/get_statistics",

    // 获取后台权限栏目
    'getPermissionMenus' : baseUrl + "Admin/users/get_permission_menus",
    'getBreadNav' : baseUrl + "Admin/menus/get_bread_nav",

    // 钩子API
    'getHooksList' : baseUrl + "Admin/hooks/index",
    'addHook' : baseUrl + "Admin/hooks/add",
    'editHook' : baseUrl + "Admin/hooks/edit/",
    'delHook' : baseUrl + "Admin/hooks/del",

    // 菜单API
    'getMenusList' : baseUrl + "Admin/menus/index",
    'addMenus' : baseUrl + "Admin/menus/add",
    'editMenus' : baseUrl + "Admin/menus/edit/",
    'delMenus' : baseUrl + "Admin/menus/del",

    // 角色API
    'getRolesList' : baseUrl + "Admin/roles/index",
    'addRoles' : baseUrl + "Admin/roles/add",
    'editRoles' : baseUrl + "Admin/roles/edit/",
    'delRoles' : baseUrl + "Admin/roles/del",

    // 用户API
    'getUsersList' : baseUrl + "Admin/users/index",
    'addUsers' : baseUrl + "Admin/users/add",
    'editUsers' : baseUrl + "Admin/users/edit/",
    'delUsers' : baseUrl + "Admin/users/del",
    'getUserInfo' : baseUrl + "Admin/users/get_user_info", // 获取用户信息
    'adminChangeMoney' : baseUrl + "Admin/users/change_money", // 获取用户信息


    // 配置中心
    'haibConfig' : baseUrl + "Admin/config/haib_config", // 海报
    'guanggtConfig' : baseUrl + "Admin/config/guanggt_config", // 广告图
    'dbguanggtConfig' : baseUrl + "Admin/config/dbguanggt_config", // 家长寄语、学籍中心底部广告图

    'kefConfig' : baseUrl + "Admin/config/kef_config", // 客服
    'toupkjConfig' : baseUrl + "Admin/config/toupkj_config", // 投票大赛开始截止时间
    'toupbmkjConfig' : baseUrl + "Admin/config/toupbmkj_config", // 投票大赛报名开始截止时间

    'yonghConfig' : baseUrl + "Admin/config/yongh_config", // 用户协议
    'ruyxyConfig' : baseUrl + "Admin/config/ruyxy_config", // 入营协议
    'baodxzConfig' : baseUrl + "Admin/config/baodxz_config", // 报道须知
    'yaoqhygzjsConfig' : baseUrl + "Admin/config/yaoqhygzjs_config", // 邀请好友规则介绍

    'webConfig' : baseUrl + "Admin/config/web_config", // 网址配置
    'uploadConfig' : baseUrl + "Admin/config/upload_config", // 上传配置
    'mapConfig' : baseUrl + "Admin/config/map_config", // 地图配置
    'wxPayH5Config' : baseUrl + "Admin/config/wxpay_h5_config", // 微信支付H5
    'logoConfig' : baseUrl + "Admin/config/logo_pc_config", // 抖音二维码
    'wxPayAppConfig' : baseUrl + "Admin/config/wxpay_app_config", // 微信支付app
    'wxPayJsApiConfig' : baseUrl + "Admin/config/wxpay_jsapi_config", // 微信支付jsapi
    'wxPayMiNiConfig' : baseUrl + "Admin/config/wxpay_mini_config", // 微信支付小程序mini
    'aliPayH5Config' : baseUrl + "Admin/config/alipay_h5_config", // 支付宝支付H5
    'aliPayAppConfig' : baseUrl + "Admin/config/alipay_app_config", // 支付宝支付APP
    'aliPayPcConfig' : baseUrl + "Admin/config/alipay_pc_config", // 支付宝支付PC
    'shiysmConfig' : baseUrl + "Admin/config/shiysm_config", // 使用说明
    'aliSmsConfig' : baseUrl + "Admin/config/alisms_config", // 阿里云sms
    'wechatPublicConfig' : baseUrl + "Admin/config/wechat_public_config", // 公众号微信
    'dingjwkPublicConfig' : baseUrl + "Admin/config/dingjwk_public_config", // 定金尾款幅度设置


    'zongdzfConfig' : baseUrl + "Admin/config/zongdzf_config", // 总代转发
    'dailzfConfig' : baseUrl + "Admin/config/dailzf_config", // 代理转发
    'putzfConfig' : baseUrl + "Admin/config/putzf_config", // 普通转发



    'freightKeyConfig' : baseUrl + "Admin/config/freight_config", // 物流配置
    'oauthConfig' : baseUrl + "Admin/config/oauth_config", // 微信第三方登录Oauth配置 pc
    'distributionConfig' : baseUrl + "Admin/config/distribution_config", // 分销配置
    'TaskTimeConfig' : baseUrl + "Admin/config/task_time_config", // 分销配置

    // 短信列表
    'getSmsLogList' : baseUrl + "Admin/sms_log/index", 
    'delSmsLog' : baseUrl + "Admin/sms_log/del",


    'logoUpload' : baseUrl + "Admin/config/logo_upload", // 网址logo上传


    'zfUpload' : baseUrl + "Admin/config/zfupload", // 自封图片上传

    // 商品分类
    'getGoodsClassList' : baseUrl + "Admin/goods_class/index", 
    'addGoodsClass' : baseUrl + "Admin/goods_class/add",
    'editGoodsClass' : baseUrl + "Admin/goods_class/edit/",
    'delGoodsClass' : baseUrl + "Admin/goods_class/del",
    'goodsClassUpload' : baseUrl + "Admin/goods_class/goods_class_upload", // 分类图片上传


    // 服务分类
    'getFuwClassList' : baseUrl + "Admin/fuw_class/index",
    'addFuwClass' : baseUrl + "Admin/fuw_class/add",
    'editFuwClass' : baseUrl + "Admin/fuw_class/edit/",
    'delFuwClass' : baseUrl + "Admin/fuw_class/del",
    'FuwClassUpload' : baseUrl + "Admin/fuw_class/fuw_class_upload", // 分类图片上传
    'editClassStatus' : baseUrl + "Admin/fuw_class/class_status",





    // 商品品牌
    'getGoodsBrandList' : baseUrl + "Admin/goods_brand/index", 
    'addGoodsBrand' : baseUrl + "Admin/goods_brand/add",
    'editGoodsBrand' : baseUrl + "Admin/goods_brand/edit/",
    'delGoodsBrand' : baseUrl + "Admin/goods_brand/del",
    'goodsBrandUpload' : baseUrl + "Admin/goods_brand/goods_brand_upload", // 品牌图片上传

    // 地区管理
    'getAreaList' : baseUrl + "Admin/area/index", 
    'addArea' : baseUrl + "Admin/area/add",
    'editArea' : baseUrl + "Admin/area/edit/",
    'delArea' : baseUrl + "Admin/area/del",
    'getAreaChildren' : baseUrl + "Admin/area/get_area_children",
    'adminGetAreaList' : baseUrl + "Admin/area/get_area_list",

    // 清空缓存
    'cacheFlush' : baseUrl + "Admin/auto/cache_flush",





    // 订单管理
    'getAdminOrderList' : baseUrl + "Admin/order/index", 
    'getAdminOrderInfo' : baseUrl + "Admin/order/info", 

    // 广告位
    'getAdvPositionList' : baseUrl + "Admin/adv_position/index", 
    'addAdvPosition' : baseUrl + "Admin/adv_position/add",
    'editAdvPosition' : baseUrl + "Admin/adv_position/edit/",
    'delAdvPosition' : baseUrl + "Admin/adv_position/del",

    // 广告
    'getAdvList' : baseUrl + "Admin/adv/index", 
    'addAdv' : baseUrl + "Admin/adv/add",
    'editAdv' : baseUrl + "Admin/adv/edit/",
    'delAdv' : baseUrl + "Admin/adv/del",
    'advUpload' : baseUrl + "Admin/adv/adv_upload", // 品牌图片上传
    'shipUpload' : baseUrl + "Admin/adv/get_wenjsc", // 视频上传




    // 秒杀商品
    'getSeckillList' : baseUrl + "Admin/seckill/index", 
    'addSeckill' : baseUrl + "Admin/seckill/add",
    'editSeckill' : baseUrl + "Admin/seckill/edit/",
    'delSeckill' : baseUrl + "Admin/seckill/del",
    'getAddSeckillGoods' : baseUrl + "Admin/seckill/get_add_seckill_goods", // 获取秒杀商品
    'addSeckillGoods' : baseUrl + "Admin/seckill/add_seckill_goods", // 添加秒杀商品
    'changeSeckillStatus' : baseUrl + "Admin/seckill/change_status", // 修改审核状态
    'delSeckillGoods' : baseUrl + "Admin/seckill/del_seckill_goods", // 删除

    'adminSeckillStatus' : baseUrl + "Admin/seckill/seckill_status",


    // 秒杀服务
    'getSeckillfuwlist' : baseUrl + "Admin/seckillfuw/index",
    'addSeckillfuw' : baseUrl + "Admin/seckillfuw/add",
    'editSeckillfuw' : baseUrl + "Admin/seckillfuw/edit/",
    'delSeckillfuw' : baseUrl + "Admin/seckillfuw/del",
    'getAddSeckillfuwfuw' : baseUrl + "Admin/seckillfuw/get_add_seckill_fuw", // 获取秒杀商品
    'addSeckillfuwfuw' : baseUrl + "Admin/seckillfuw/add_seckill_fuw", // 添加秒杀服务
    'changeSeckillfuwStatus' : baseUrl + "Admin/seckillfuw/change_status", // 修改审核状态
    'delSeckillfuwfuw' : baseUrl + "Admin/seckillfuw/del_seckillfuw_goods", // 删除


    // 站点协议
    'getAgreementList' : baseUrl + "Admin/agreement/index", 
    'addAgreement' : baseUrl + "Admin/agreement/add",
    'editAgreement' : baseUrl + "Admin/agreement/edit/",
    'delAgreement' : baseUrl + "Admin/agreement/del",

    // 店铺列表
    'getStoreList' : baseUrl + "Admin/store/index", 
    'delStore' : baseUrl + "Admin/store/del",
    'StorePass' : baseUrl + "Admin/store/store_pass",

    // 积分商城
    'getIntegralList' : baseUrl + "Admin/integral/index", 
    'addIntegral' : baseUrl + "Admin/integral/add", 
    'editIntegral' : baseUrl + "Admin/integral/edit", 
    'delIntegral' : baseUrl + "Admin/integral/del", 
    'goodsStatusIntegral' : baseUrl + "Admin/integral/goods_status", 
    'goodsHotIntegral' : baseUrl + "Admin/integral/goods_hot", 
    'integralUpload' : baseUrl + "Admin/integral/integral_upload", 

    // 积分商城分类
    'adminGetIntegralClassList' : baseUrl + "Admin/integral_class/index", 
    'adminAddIntegralClass' : baseUrl + "Admin/integral_class/add",
    'adminEditIntegralClass' : baseUrl + "Admin/integral_class/edit/",
    'adminDelIntegralClass' : baseUrl + "Admin/integral_class/del",

    // 积分订单
    'adminGetIntegralOrderList' : baseUrl + "Admin/integral_order/index", 
    'adminGetIntegralOrderInfo' : baseUrl + "Admin/integral_order/info", 
    'adminIntegralOrderSendDelivery' : baseUrl + "Admin/integral_order/send_delivery", 

    // 资金提现
    'adminGetCashList' : baseUrl + "Admin/cash/index", 
    'adminDelCash' : baseUrl + "Admin/cash/del", 
    'adminCashChangeStatus' : baseUrl + "Admin/cash/change_status", // 修改打款状态


	 // 设置-常见问题
    'getComproblemList' : baseUrl + "Admin/comproblem/index",
    'addComproblem' : baseUrl + "Admin/comproblem/add",
    'editComproblem' : baseUrl + "Admin/comproblem/edit/",
    'delComproblem' : baseUrl + "Admin/comproblem/del",

    //轮播图
    'getLunbtList' : baseUrl + "Admin/lunbt/index",
    'addLunbt' : baseUrl + "Admin/lunbt/add",
    'editLunbt' : baseUrl + "Admin/lunbt/edit/",
    'delLunbt' : baseUrl + "Admin/lunbt/del",

    //课程资料
    'getKeczl' : baseUrl + "Admin/keczl/index",
    'editKeczl' : baseUrl + "Admin/keczl/edit/",


    //家长寄语轮播图
    'getJzjylunbt' : baseUrl + "Admin/jiazjylunbt/index",
    'addJzjylunbt' : baseUrl + "Admin/jiazjylunbt/add",
    'editJzjylunbt' : baseUrl + "Admin/jiazjylunbt/edit/",
    'delJzjylunbt' : baseUrl + "Admin/jiazjylunbt/del",

    //短视频平台轮播图
    'getDuanspptlunbt' : baseUrl + "Admin/duansplunbt/index",
    'addDuanspptlunbt' : baseUrl + "Admin/duansplunbt/add",
    'editDuanspptlunbt' : baseUrl + "Admin/duansplunbt/edit/",
    'delDuanspptlunbt' : baseUrl + "Admin/duansplunbt/del",

    //投票大赛轮播图
    'getToupdslunbt' : baseUrl + "Admin/toupdslunbt/index",
    'addToupdslunbt' : baseUrl + "Admin/toupdslunbt/add",
    'editToupdslunbt' : baseUrl + "Admin/toupdslunbt/edit/",
    'delToupdslunbt' : baseUrl + "Admin/toupdslunbt/del",


    //营地商城宣传视频
    'getYingdscxcsp' : baseUrl + "Admin/yingdscxcsp/index",
    'addYingdscxcsp' : baseUrl + "Admin/yingdscxcsp/add",
    'delYingdscxcsp' : baseUrl + "Admin/yingdscxcsp/del",
    'xcspVerifyChange' : baseUrl + "Admin/yingdscxcsp/xcsp_verify_change",






    //营地商城轮播图
    'getYingdsclunbt' : baseUrl + "Admin/yingdsclunbt/index",
    'addYingdsclunbt' : baseUrl + "Admin/yingdsclunbt/add",
    'editYingdsclunbt' : baseUrl + "Admin/yingdsclunbt/edit/",
    'delYingdsclunbt' : baseUrl + "Admin/yingdsclunbt/del",

    //在线报名轮播图
    'getZaixbmlunbt' : baseUrl + "Admin/zaixbmlunbt/index",
    'addZaixbmlunbt' : baseUrl + "Admin/zaixbmlunbt/add",
    'editZaixbmlunbt' : baseUrl + "Admin/zaixbmlunbt/edit/",
    'delZaixbmlunbt' : baseUrl + "Admin/zaixbmlunbt/del",





    //基地
    'getJidList' : baseUrl + "Admin/jid/index",
    'addJid' : baseUrl + "Admin/jid/add",
    'editJid' : baseUrl + "Admin/jid/edit/",
    'delJid' : baseUrl + "Admin/jid/del",

    //营期
    'getYingqList' : baseUrl + "Admin/yingq/index",
    'addYingq' : baseUrl + "Admin/yingq/add",
    'editYingq' : baseUrl + "Admin/yingq/edit/",
    'delYingq' : baseUrl + "Admin/yingq/del",

    //营期期限
    'getYingqqxList' : baseUrl + "Admin/yingqqx/index",
    'addYingqqx' : baseUrl + "Admin/yingqqx/add",
    'editYingqqx' : baseUrl + "Admin/yingqqx/edit/",
    'delYingqqx' : baseUrl + "Admin/yingqqx/del",

    //班级
    'getBanjList' : baseUrl + "Admin/banj/index",
    'addBanj' : baseUrl + "Admin/banj/add",
    'editBanj' : baseUrl + "Admin/banj/edit/",
    'delBanj' : baseUrl + "Admin/banj/del",

    //基地通知
    'getJidtzList' : baseUrl + "Admin/jidtz/index",
    'addJidtz' : baseUrl + "Admin/jidtz/add",
    'editJidtz' : baseUrl + "Admin/jidtz/edit/",
    'delJidtz' : baseUrl + "Admin/jidtz/del",

    //课程商城
    'getKecscList' : baseUrl + "Admin/kecsc/index",
    'addKecsc' : baseUrl + "Admin/kecsc/add",
    'editKecsc' : baseUrl + "Admin/kecsc/edit/",
    'delKecsc' : baseUrl + "Admin/kecsc/del",

    //课程商城评价
    'getKecscpjList' : baseUrl + "Admin/kecscpj/index",
    'addKecscpj' : baseUrl + "Admin/kecscpj/add",
    'editKecscpj' : baseUrl + "Admin/kecscpj/edit/",
    'delKecscpj' : baseUrl + "Admin/kecscpj/del",


    //相册商城
    'getXiangcscList' : baseUrl + "Admin/xiangcsc/index",
    'addXiangcsc' : baseUrl + "Admin/xiangcsc/add",
    'editXiangcsc' : baseUrl + "Admin/xiangcsc/edit/",
    'delXiangcsc' : baseUrl + "Admin/xiangcsc/del",


    //相册商城评价
    'getXiangcscpjList' : baseUrl + "Admin/xiangcscpj/index",
    'addXiangcscpj' : baseUrl + "Admin/xiangcscpj/add",
    'editXiangcscpj' : baseUrl + "Admin/xiangcscpj/edit/",
    'delXiangcscpj' : baseUrl + "Admin/xiangcscpj/del",







    //相册商城订单
    'getXiangcscddList' : baseUrl + "Admin/xiangcscdd/index",
    'getXiangcscddInfo' : baseUrl + "Admin/xiangcscdd/info",
    'delXiangcscdd' : baseUrl + "Admin/xiangcscdd/del",
    'adminChangeDingdh' : baseUrl + "Admin/xiangcscdd/changedingdh", // 获取用户信息


    //课程商城订单
    'getKecscddList' : baseUrl + "Admin/kecscdd/index",
    'getKecscddInfo' : baseUrl + "Admin/kecscdd/info",

    //学员列表
    'getXueyxjList' : baseUrl + "Admin/xueyxj/index",
    'addXueyxj' : baseUrl + "Admin/xueyxj/add",
    'editXueyxj' : baseUrl + "Admin/xueyxj/edit/",
    'delXueyxj' : baseUrl + "Admin/xueyxj/del",
    'exportXueyxj' : baseUrl + "Admin/xueyxj/export",

    'editXueyxjStatus' : baseUrl + "Admin/xueyxj/zhit_status",

    'fafXueyxjStatus' : baseUrl + "Admin/xueyxj/faf_status",


    'yijshXueyxjStatus' : baseUrl + "Admin/xueyxj/yijsh_status",


    'exportxuey' : baseUrl + "Admin/excel/xuey",

    'excelimport' : baseUrl + "Admin/excel/excelimport",


    //提现列表
    'getTixList' : baseUrl + "Admin/tix/index",
    'delTix' : baseUrl + "Admin/tix/del",
    'tixStatus' : baseUrl + "Admin/tix/status",



    //学员消息推送时间设置
    'xxtsszConfig' : baseUrl + "Admin/config/xxtssz",
    //消息推送列表
    'getXxlb' : baseUrl + "Admin/xxlb/index",
    'addXxlb' : baseUrl + "Admin/xxlb/add",
    'editXxlb' : baseUrl + "Admin/xxlb/edit/",
    'delXxlb' : baseUrl + "Admin/xxlb/del",


    //私人定制
    'getSirdzList' : baseUrl + "Admin/sirdz/index",
    'addSirdz' : baseUrl + "Admin/sirdz/add",
    'editSirdz' : baseUrl + "Admin/sirdz/edit/",
    'delSirdz' : baseUrl + "Admin/sirdz/del",

    //私人定制订单
    'getSirdzddList' : baseUrl + "Admin/sirdzdd/index",
    'addSirdzdd' : baseUrl + "Admin/sirdzdd/add",
    'editSirdzdd' : baseUrl + "Admin/sirdzdd/edit/",
    'delSirdzdd' : baseUrl + "Admin/sirdzdd/del",

    //照片直播首页
    'getZhaopzbsyList' : baseUrl + "Admin/zhaopzbsy/index",
    'addZhaopzbsy' : baseUrl + "Admin/zhaopzbsy/add",
    'editZhaopzbsy' : baseUrl + "Admin/zhaopzbsy/edit/",
    'delZhaopzbsy' : baseUrl + "Admin/zhaopzbsy/del",

    //图片直播
    'getTupzbList' : baseUrl + "Admin/tupzb/index",
    'addTupzb' : baseUrl + "Admin/tupzb/add",
    'editTupzb' : baseUrl + "Admin/tupzb/edit/",
    'delTupzb' : baseUrl + "Admin/tupzb/del",


    //照片直播热门
    'getZhaopzbrmList' : baseUrl + "Admin/zhaopzbrm/index",
    'addZhaopzbrm' : baseUrl + "Admin/zhaopzbrm/add",
    'editZhaopzbrm' : baseUrl + "Admin/zhaopzbrm/edit/",
    'delZhaopzbrm' : baseUrl + "Admin/zhaopzbrm/del",

    //照片直播视频
    'getZhaopzbspList' : baseUrl + "Admin/zhaopzbsp/index",
    'addZhaopzbsp' : baseUrl + "Admin/zhaopzbsp/add",
    'editZhaopzbsp' : baseUrl + "Admin/zhaopzbsp/edit/",
    'delZhaopzbsp' : baseUrl + "Admin/zhaopzbsp/del",




    //招生老师
    'getZslsList' : baseUrl + "Admin/zsls/index",
    'addZsls' : baseUrl + "Admin/zsls/add",
    'editZsls' : baseUrl + "Admin/zsls/edit/",
    'delZsls' : baseUrl + "Admin/zsls/del",

    //普通会员
    'getPuthyList' : baseUrl + "Admin/puthy/index",


    //一级代理
    'getYijdlList' : baseUrl + "Admin/yijdl/index",


    //二级代理
    'getErjdlList' : baseUrl + "Admin/erjdl/index",





    //招生老师
    'getXianxlsList' : baseUrl + "Admin/xianxls/index",
    'addXianxls' : baseUrl + "Admin/xianxls/add",
    'editXianxls' : baseUrl + "Admin/xianxls/edit/",
    'delXianxls' : baseUrl + "Admin/xianxls/del",



    //公众号关注列表
    'getGzhgzList' : baseUrl + "Admin/gzhgz/index",
    'addGzhgz' : baseUrl + "Admin/gzhgz/add",
    'editGzhgz' : baseUrl + "Admin/gzhgz/edit/",
    'delGzhgz' : baseUrl + "Admin/gzhgz/del",

    //线索列表
    'getXianslbList' : baseUrl + "Admin/xianslb/index",
    'addXianslb' : baseUrl + "Admin/xianslb/add",
    'editXianslb' : baseUrl + "Admin/xianslb/edit/",
    'delXianslb' : baseUrl + "Admin/xianslb/del",


    //推送消息
    'getXxtsList' : baseUrl + "Admin/xxts/index",
    'addXxts' : baseUrl + "Admin/xxts/add",
    'editXxts' : baseUrl + "Admin/xxts/edit/",
    'delXxts' : baseUrl + "Admin/xxts/del",
    'postXxts' : baseUrl + "Admin/xxts/tuis",


    //学员推送消息
    'getXyxjxxtsList' : baseUrl + "Admin/xyxjxxts/index",
    'addXyxjxxts' : baseUrl + "Admin/xyxjxxts/add",
    'editXyxjxxts' : baseUrl + "Admin/xyxjxxts/edit/",
    'delXyxjxxts' : baseUrl + "Admin/xyxjxxts/del",
    'postXyxjxxts' : baseUrl + "Admin/xyxjxxts/tuis",



    //入营通知书
    'getTongzsList' : baseUrl + "Admin/tongzs/index",
    'addTongzs' : baseUrl + "Admin/tongzs/add",
    'editTongzs' : baseUrl + "Admin/tongzs/edit/",
    'delTongzs' : baseUrl + "Admin/tongzs/del",

    //在线报名
    'getZaixbmList' : baseUrl + "Admin/zaixbm/index",
    'addZaixbm' : baseUrl + "Admin/zaixbm/add",
    'editZaixbm' : baseUrl + "Admin/zaixbm/edit/",
    'delZaixbm' : baseUrl + "Admin/zaixbm/del",
    'getZaixbmInfo' : baseUrl + "Admin/zaixbm/info",

    //投票大赛
    'getToupdsList' : baseUrl + "Admin/toupds/index",
    'addToupds' : baseUrl + "Admin/toupds/add",
    'editToupds' : baseUrl + "Admin/toupds/edit/",
    'delToupds' : baseUrl + "Admin/toupds/del",
    'toupdsVerifyChange' : baseUrl + "Admin/toupds/toupds_verify_change",



    //报名选手
    'getXuansbmList' : baseUrl + "Admin/xuansbm/index",
    'addXuansbm' : baseUrl + "Admin/xuansbm/add",
    'editXuansbm' : baseUrl + "Admin/xuansbm/edit/",
    'delXuansbm' : baseUrl + "Admin/xuansbm/del",


    //家长寄语
    'getJiazjyList' : baseUrl + "Admin/jiazjy/index",
    'addJiazjy' : baseUrl + "Admin/jiazjy/add",
    'editJiazjy' : baseUrl + "Admin/jiazjy/edit/",
    'delJiazjy' : baseUrl + "Admin/jiazjy/del",
    'jiazjyVerifyChange' : baseUrl + "Admin/jiazjy/jiazjy_verify_change",


    //公告文字
    'getGongg' : baseUrl + "Admin/gongg/index",
    'addGongg' : baseUrl + "Admin/gongg/add",
    'editGongg' : baseUrl + "Admin/gongg/edit/",
    'delGongg' : baseUrl + "Admin/gongg/del",


    //短视频营期
    'getDuanspyq' : baseUrl + "Admin/duanspyq/index",
    'addDuanspyq' : baseUrl + "Admin/duanspyq/add",
    'editDuanspyq' : baseUrl + "Admin/duanspyq/edit/",
    'delDuanspyq' : baseUrl + "Admin/duanspyq/del",

    //短视频列表
    'getDuansplb' : baseUrl + "Admin/duansplb/index",
    'addDuansplb' : baseUrl + "Admin/duansplb/add",
    'editDuansplb' : baseUrl + "Admin/duansplb/edit/",
    'delDuansplb' : baseUrl + "Admin/duansplb/del",
    'duansplbChange' : baseUrl + "Admin/duansplb/duansplb_change",

    //音乐列表
    'getYinylb' : baseUrl + "Admin/yinylb/index",
    'addYinylb' : baseUrl + "Admin/yinylb/add",
    'editYinylb' : baseUrl + "Admin/yinylb/edit/",
    'delYinylb' : baseUrl + "Admin/yinylb/del",

    'fileUpload' : baseUrl + "Admin/adv/file_upload", // 品牌图片上传


    //尾款列表
    'getWeiklb' : baseUrl + "Admin/weik/index",
    'addWeiklb' : baseUrl + "Admin/weik/add",
    'editWeiklb' : baseUrl + "Admin/weik/edit/",
    'delWeiklb' : baseUrl + "Admin/weik/del",
    'weikVerifyChange' : baseUrl + "Admin/weik/weik_verify_change",




    /*************************美甲纹绣*********************/

    //系统消息
    'getXitxxList' : baseUrl + "Admin/xitxx/index",
    'addXitxx' : baseUrl + "Admin/xitxx/add",
    'editXitxx' : baseUrl + "Admin/xitxx/edit/",
    'delXitxx' : baseUrl + "Admin/xitxx/del",

    //活动消息
    'getHuodxxList' : baseUrl + "Admin/huodxx/index",
    'addHuodxx' : baseUrl + "Admin/huodxx/add",
    'editHuodxx' : baseUrl + "Admin/huodxx/edit/",
    'delHuodxx' : baseUrl + "Admin/huodxx/del",




    //门店列表
    'getMendList' : baseUrl + "Admin/mend/index",
    'addMend' : baseUrl + "Admin/mend/add",
    'editMend' : baseUrl + "Admin/mend/edit/",
    'delMend' : baseUrl + "Admin/mend/del",


    //意见反馈
    'getYijfkList' : baseUrl + "Admin/yijfk/index",
    'addYijfk' : baseUrl + "Admin/yijfk/add",
    'editYijfk' : baseUrl + "Admin/yijfk/edit/",
    'delYijfk' : baseUrl + "Admin/yijfk/del",

    //意见反馈类型
    'getYijfklxList' : baseUrl + "Admin/yijfklx/index",
    'addYijfklx' : baseUrl + "Admin/yijfklx/add",
    'editYijfklx' : baseUrl + "Admin/yijfklx/edit/",
    'delYijfklx' : baseUrl + "Admin/yijfklx/del",

    //常见问题
    'getChangjwtList' : baseUrl + "Admin/changjwt/index",
    'addChangjwt' : baseUrl + "Admin/changjwt/add",
    'editChangjwt' : baseUrl + "Admin/changjwt/edit/",
    'delChangjwt' : baseUrl + "Admin/changjwt/del",

    //优惠问题
    'getYouhwtList' : baseUrl + "Admin/youhwt/index",
    'addYouhwt' : baseUrl + "Admin/youhwt/add",
    'editYouhwt' : baseUrl + "Admin/youhwt/edit/",
    'delYouhwt' : baseUrl + "Admin/youhwt/del",

    //秒杀问题
    'getMiaoswtList' : baseUrl + "Admin/miaoswt/index",
    'addMiaoswt' : baseUrl + "Admin/miaoswt/add",
    'editMiaoswt' : baseUrl + "Admin/miaoswt/edit/",
    'delMiaoswt' : baseUrl + "Admin/miaoswt/del",

    //支付问题
    'getZhifwtList' : baseUrl + "Admin/zhifwt/index",
    'addZhifwt' : baseUrl + "Admin/zhifwt/add",
    'editZhifwt' : baseUrl + "Admin/zhifwt/edit/",
    'delZhifwt' : baseUrl + "Admin/zhifwt/del",

    //活动问题
    'getHuodwtList' : baseUrl + "Admin/huodwt/index",
    'addHuodwt' : baseUrl + "Admin/huodwt/add",
    'editHuodwt' : baseUrl + "Admin/huodwt/edit/",
    'delHuodwt' : baseUrl + "Admin/huodwt/del",




    //线下订单
    'getXianxddList' : baseUrl + "Admin/xianxdd/index",
    'addXianxdd' : baseUrl + "Admin/xianxdd/add",
    'editXianxdd' : baseUrl + "Admin/xianxdd/edit/",
    'delXianxdd' : baseUrl + "Admin/xianxdd/del",
    'xianxddVerifyChange' : baseUrl + "Admin/xianxdd/xianxdd_verify_change",


    //线下订单补色
    'getXianxddbsList' : baseUrl + "Admin/xianxddbs/index",
    'addXianxddbs' : baseUrl + "Admin/xianxddbs/add",
    'editXianxddbs' : baseUrl + "Admin/xianxddbs/edit/",
    'delXianxddbs' : baseUrl + "Admin/xianxddbs/del",


    //线下订单套餐
    'getXianxddtcList' : baseUrl + "Admin/xianxddtc/index",
    'addXianxddtc' : baseUrl + "Admin/xianxddtc/add",
    'editXianxddtc' : baseUrl + "Admin/xianxddtc/edit/",
    'delXianxddtc' : baseUrl + "Admin/xianxddtc/del",


    //线下订单消费来源
    'getXianxddxflyList' : baseUrl + "Admin/xianxddxfly/index",
    'addXianxddxfly' : baseUrl + "Admin/xianxddxfly/add",
    'editXianxddxfly' : baseUrl + "Admin/xianxddxfly/edit/",
    'delXianxddxfly' : baseUrl + "Admin/xianxddxfly/del",


    //线下订单消费来源
    'getXianxddxffsList' : baseUrl + "Admin/xianxddxffs/index",
    'addXianxddxffs' : baseUrl + "Admin/xianxddxffs/add",
    'editXianxddxffs' : baseUrl + "Admin/xianxddxffs/edit/",
    'delXianxddxffs' : baseUrl + "Admin/xianxddxffs/del",



    //评价中心
    'getPingjList' : baseUrl + "Admin/pingj/index",
    'addPingj' : baseUrl + "Admin/pingj/add",
    'editPingj' : baseUrl + "Admin/pingj/edit/",
    'delPingj' : baseUrl + "Admin/pingj/del",

    //满多少可用到时优惠券
    'getMankyyhqList' : baseUrl + "Admin/mankyyhq/index",
    'addMankyyhq' : baseUrl + "Admin/mankyyhq/add",
    'editMankyyhq' : baseUrl + "Admin/mankyyhq/edit/",
    'delMankyyhq' : baseUrl + "Admin/mankyyhq/del",

    //新人到时优惠券
    'getXinryhqList' : baseUrl + "Admin/xinryhq/index",
    'addXinryhq' : baseUrl + "Admin/xinryhq/add",
    'editXinryhq' : baseUrl + "Admin/xinryhq/edit/",
    'delXinryhq' : baseUrl + "Admin/xinryhq/del",

    //分享人到时优惠券
    'getFenxyhqList' : baseUrl + "Admin/fenxyhq/index",
    'addFenxyhq' : baseUrl + "Admin/fenxyhq/add",
    'editFenxyhq' : baseUrl + "Admin/fenxyhq/edit/",
    'delFenxyhq' : baseUrl + "Admin/fenxyhq/del",





    //其它问题
    'getQitwtList' : baseUrl + "Admin/qitwt/index",
    'addQitwt' : baseUrl + "Admin/qitwt/add",
    'editQitwt' : baseUrl + "Admin/qitwt/edit/",
    'delQitwt' : baseUrl + "Admin/qitwt/del",



    // 商家自定义分类
    'getStoreGoodsClassList' : baseUrl + "Admin/store_goods_class/index",
    'addStoreGoodsClass' : baseUrl + "Admin/store_goods_class/add",
    'editStoreGoodsClass' : baseUrl + "Admin/store_goods_class/edit/",
    'delStoreGoodsClass' : baseUrl + "Admin/store_goods_class/del",


    // 商品规格属性
    'getAttrSpecList' : baseUrl + "Admin/attr_spec/index",
    'addAttrSpec' : baseUrl + "Admin/attr_spec/add",
    'editAttrSpec' : baseUrl + "Admin/attr_spec/edit/",
    'delAttrSpec' : baseUrl + "Admin/attr_spec/del",


    // 服务套餐属性
    'getFuwAttrSpecList' : baseUrl + "Admin/fuwattr_spec/index",
    'addFuwAttrSpec' : baseUrl + "Admin/fuwattr_spec/add",
    'editFuwAttrSpec' : baseUrl + "Admin/fuwattr_spec/edit/",
    'delFuwAttrSpec' : baseUrl + "Admin/fuwattr_spec/del",



    // 商品
    /*'goodsUpload' : baseUrl +"Admin/goods/goods_upload",
    'getGoodsList' : baseUrl + "Admin/goods/index",
    'addGoods' : baseUrl + "Admin/goods/add",
    'editGoods' : baseUrl + "Admin/goods/edit/",
    'delGoods' : baseUrl + "Admin/goods/del",
    'goodsStatus' : baseUrl + "Admin/goods/goods_status",
*/

    // 商品管理
    // 选择商品分类
    'GetStoreClass' : baseUrl + "Admin/goods/get_store_class",
    'getAdminGoodsList' : baseUrl + "Admin/goods/index",
    'addGoods' : baseUrl + "Admin/goods/add",
    'editGoods' : baseUrl + "Admin/goods/edit/",
    'delGoods' : baseUrl + "Admin/goods/del",
    'adminGoodsStatus' : baseUrl + "Admin/goods/goods_status",
    'adminGoodsJfstatus' : baseUrl + "Admin/goods/goods_jfstatus",

    'adminGoodsIndex' : baseUrl + "Admin/goods/goods_index",
    'goodsVerify' : baseUrl + "Admin/goods/goods_verify",
    'goodsVerifyChange' : baseUrl + "Admin/goods/goods_verify_change",
    'goodsUpload' : baseUrl +"Admin/goods/goods_upload",



    // 服务管理
    // 选择服务分类
    'GetFuwStoreClass' : baseUrl + "Admin/fuw/get_store_class",
    'getAdminFuwList' : baseUrl + "Admin/fuw/index",


    'addFuw' : baseUrl + "Admin/fuw/add",
    'editFuw' : baseUrl + "Admin/fuw/edit/",
    'delFuw' : baseUrl + "Admin/fuw/del",
    'adminFuwStatus' : baseUrl + "Admin/fuw/fuw_status",
    'adminFuwIndex' : baseUrl + "Admin/fuw/fuw_index",
    'fuwVerify' : baseUrl + "Admin/fuw/fuw_verify",
    'fuwVerifyChange' : baseUrl + "Admin/fuw/fuw_verify_change",
    'fuwUpload' : baseUrl +"Admin/fuw/fuw_upload",



    /*************************美甲纹绣*********************/








    /**
     * @deprecated 商家端 视图
     * @author hg <www.qingwuit.com>
     */
    
    "sellerLogin" : baseUrl + "Seller/login", // 登录

    // 获取统计数据
    'sellerGetStatistics' : baseUrl + "Seller/index/get_statistics",

    // 规格属性
    /*'getAttrSpecList' : baseUrl + "Seller/attr_spec/index",
    'addAttrSpec' : baseUrl + "Seller/attr_spec/add",
    'editAttrSpec' : baseUrl + "Seller/attr_spec/edit/",
    'delAttrSpec' : baseUrl + "Seller/attr_spec/del",*/

    // 商家自定义分类
/*    'getStoreGoodsClassList' : baseUrl + "Seller/store_goods_class/index",
    'addStoreGoodsClass' : baseUrl + "Seller/store_goods_class/add",
    'editStoreGoodsClass' : baseUrl + "Seller/store_goods_class/edit/",
    'delStoreGoodsClass' : baseUrl + "Seller/store_goods_class/del",*/

    // 商品
    /*'goodsUpload' : baseUrl +"Seller/goods/goods_upload",
    'getGoodsList' : baseUrl + "Seller/goods/index", 
    'addGoods' : baseUrl + "Seller/goods/add",
    'editGoods' : baseUrl + "Seller/goods/edit/",
    'delGoods' : baseUrl + "Seller/goods/del",
    'goodsStatus' : baseUrl + "Seller/goods/goods_status",
*/
    // 订单管理
    'getSellerOrderList' : baseUrl + "Seller/order/index", 
    'getSellerOrderInfo' : baseUrl + "Seller/order/info", 
    'getSellerSendDelivery' : baseUrl + "Seller/order/send_delivery", 
    'getSellerRefund' : baseUrl + "Seller/order/refund",  // 同意申请售后
    'getSellerRefundMoney' : baseUrl + "Seller/order/refund_money",  // 同意退钱

    // 团购订单
    'getSellerGroupbuyOrderList' : baseUrl + "Seller/groupbuy/index", 
    'getSellerGroupbuyUserList' : baseUrl + "Seller/groupbuy/get_groupbuy_user", 

    // 运费模版
    'getFreightTemplateList' : baseUrl + "Seller/freight_template/index", 
    'addFreightTemplate' : baseUrl + "Seller/freight_template/add",
    'editFreightTemplate' : baseUrl + "Seller/freight_template/edit/",
    'delFreightTemplate' : baseUrl + "Seller/freight_template/del",
    

    'storeInfoSetting' : baseUrl + "Seller/store/setting",
    'storeFreeFreightSetting' : baseUrl + "Seller/free_freight/setting",  // 设置免多少包邮
    'storeAfterSaleContent' : baseUrl + "Seller/store/after_sale_content",  // 设置商品详情页面售后服务
    'sellerGetAreaList' : baseUrl + "Seller/area/get_area_list",
    'storeLogoUpload' : baseUrl + "Seller/store/logo_upload",

    // 秒杀商品
    'sellerGetSeckillList' : baseUrl + "Seller/seckill/index", 
    'sellerAddSeckill' : baseUrl + "Seller/seckill/add",
    'sellerEditSeckill' : baseUrl + "Seller/seckill/edit/",
    'sellerDelSeckill' : baseUrl + "Seller/seckill/del",
    'sellerAddSeckillGoods' : baseUrl + "Seller/seckill/add_seckill_goods", // 添加秒杀商品
    'sellerGetAddSeckillGoods' : baseUrl + "Seller/seckill/get_add_seckill_goods", // 获取秒杀商品
    'sellerDelSeckillGoods' : baseUrl + "Admin/seckill/del_seckill_goods", // 删除





    // 富文本上传
    'autoUpload' : baseUrl +"Seller/auto/auto_upload",


    /**
     * @deprecated PC端 视图
     * @author hg <www.qingwuit.com>
     */

    // 用户登录注册
    "homeLogin" : baseUrl + "user/login", // 登录
    "homeLogout" : baseUrl + "user/logout", // 注销账号
    "homeGetOauthConfig" : baseUrl + "user/get_oauth_config", // 获取OAUTH
    "homeRegister" : baseUrl + "user/register", // 注册
    "homeForgetPassword" : baseUrl + "user/forget_password", // 忘记密码
    "homeSendSms" : baseUrl + "user/send_sms", // 发送短信
    "homeSendEmail" : baseUrl + "user/send_email", // 发送邮件

    "homeGetUserInfo" : baseUrl + "user/get_user_info",// 获取用户信息
    "homeEditUserInfo" : baseUrl + "user/edit_user_info",// 修改用户信息
    "homeCheckPayPassword" : baseUrl + "user/check_pay_password",// 验证支付密码
    "homeGetFavList" : baseUrl + "user/get_fav_list",// 修改收藏关注列表
    "homeDelFav" : baseUrl + "user/del_fav",// 删除收藏关注
    "homeGetMoneyLog" : baseUrl + "user/get_money_log",// 获取用户资金变更日志
    "homeAvatar" : baseUrl + "user/avatar",// 用户头像上传

    // 邀请信息
    "homeGetInviterInfo" : baseUrl + "user/get_inviter_info",// 用户头像上传

    // 认证信息
    "homeGetUserCheckInfo" : baseUrl + "user/get_user_check_info",// 获取用户认证信息
    "homeEditUserCheckInfo" : baseUrl + "user/edit_user_check_info",// 修改用户认证信息
    "homeUserCard" : baseUrl + "user/user_card",// 身份证上传

    // 商城首页接口
    "homeGetSubNavInfo" : baseUrl + "index/get_subnav_info", // 获取首页左侧导航的信息
    "homeGetIndexInfo" : baseUrl + "index/get_index_info", // 获取首页信息
    "homeGetFootInfo" : baseUrl + "index/get_foot_info", // 获取公共底部信息

    // 秒杀接口
    "homeGetSeckillList" : baseUrl + "seckill/get_seckill_list", // 获取秒杀

    // 商家入驻
    "homeStoreJoin" : baseUrl + "user/join", 
    "homeGetJoinIndexAdv" : baseUrl + "index/get_join_index_adv", // // 获取加入我们幻灯片
    "homeIsStore" : baseUrl + "store/is_store", // // 获取加入我们幻灯片

    // 商品详情
    "homeGetGoodsInfo" : baseUrl + "goods/get_goods_info",
    "homeSearchGoods" : baseUrl + "goods/search_goods",
    "homeGetBrandList" : baseUrl + "goods/get_brand_list",
    'homeGetCommentListByGoods' : baseUrl + "goods/get_comment_list_by_goods",  // 获取评论列表根据商品ID

    // 收藏接口
    "homeIsFav" : baseUrl + "fav/is_fav",
    "homeEditFav" : baseUrl + "fav/edit_fav",

    // 商家首页
    "homeGetStoreInfo" : baseUrl + "store/get_store_info", // 获取商家信息
    "homeGetStoreIndexInfo" : baseUrl + "store/get_store_index_info", // 获取商家首页信息
    "homeGetStoreGoodsClass" : baseUrl + "store/get_store_class", // 获取商家分类信息
    "homeGetStoreClassGoods" : baseUrl + "store/get_store_class_goods", // 获取商家分类信息和商品列表

    // 积分商城
    "homeGetIntegralIndexInfo" : baseUrl + "integral/get_index_info", // 
    "homeGetIntegralGoodsInfo" : baseUrl + "integral/goods/info/", // 获取积分商品详情
    "homeSearchIntegralGoods" : baseUrl + "integral/goods/search_integral_goods", // 获取积分条件列表
    "homeGetIntegralGoodsClass" : baseUrl + "integral/goods/get_integral_goods_class", // 获取积分商品分类

    // 积分商城创建订单
    "homeIntegralCreateOrder" : baseUrl + "integral/order/create_order", // 获取积分商品分类
    "homeGetIntegralUserOrder" : baseUrl + "user/integral/get_user_order", // 获取积分订单
    "homeIntegralChangeOrderStatus" : baseUrl + "user/integral/change_order_status", // 确认收货

    // 拼团 团购
    "homeGetGroupbuyBanner" : baseUrl + "groupbuy/get_groupbuy_banner", // 团购幻灯片

    // 商品销售排行
    "homeGetSaleList" : baseUrl + "goods/get_sale_list",

    // 购物车
    "homeAddCart" : baseUrl + "cart/add_cart",
    "homeChangeCart" : baseUrl + "cart/change_cart",
    "homeDelCart" : baseUrl + "cart/del_cart",
    "homeGetCartList" : baseUrl + "cart/get_cart_list",
    "homeGetCartCount" : baseUrl + "cart/get_cart_count",

    // 用户中心
    "homeGetUserDefault" : baseUrl + "user/get_user_default", // 默认页面
    "homeGetUserOrder" : baseUrl + "user/get_user_order", // 默认页面

    // 用户收货地址
    'getAddressList' : baseUrl + "address/index", 
    'addAddress' : baseUrl + "address/add",
    'editAddress' : baseUrl + "address/edit/",
    'delAddress' : baseUrl + "address/del",
    'setDefaultAddress' : baseUrl + "address/set_default",

    // 获取物流信息
    'homeGetDeliveryList' : baseUrl + "order/get_user_delivery",

    // 订单评论
    'homeGetOrderInfoByOrderId' : baseUrl + "order/get_order_info_by_order_id",  // 根据订单ID获取订单信息
    'homeGetCommentList' : baseUrl + "order/get_comment_list",  // 根据订单ID获取订单信息
    'homeAddComment' : baseUrl + "order/add_comment",  // 添加评论
    'homeCommentImage' : baseUrl + "comment/comment_image",  // 图片上传 评论

    // 修改订单状态
    'homeChangeOrderStatus' : baseUrl + "order/change_order_status",
    'homeOrderRefund' : baseUrl + "order/refund", // 申请售后
    'homeOrderRefundDelivery' : baseUrl + "order/refund_delivery_no", // 售后发送快递单号

    // 预生成订单数据
    'homeGetBeforOrder' : baseUrl + "order/get_befor_order",

    // 生成订单
    'homeCreateOrder' : baseUrl + "order/create_order",
    'homeGetOrderInfoByOrderNo' : baseUrl + "order/get_order_info_by_order_no",  // 根据订单号获取订单信息
    'homePayOrder' : baseUrl + "order/pay",  // 支付订单
    'homeCheckWxpayNative' : baseUrl + "order/check_wxpay_native",  // 查下订单支付情况

    // 取消订单
    'homeCloseOrder' : baseUrl + "order/close_order",

    // 提现 cash
    'homeGetCashLog' : baseUrl + "cash/get_cash_log",
    'homeAddCashLog' : baseUrl + "cash/add_cash",

    // 无压缩裁剪上传
    'homeAutoUpload' : baseUrl +"auto/auto_upload",

    /**
     * 公共接口
     * @author hg <www.qingwuit.com>
     */

    // 获取协议数据(根据ename)
    "homeGetAgreementInfo" : baseUrl + "api/get_agreement_info",

    // 获取省市区信息
    "homeGetAreaList" : baseUrl + "api/get_area_list",

    // 获取PC商品分类信息
    "homeGetGoodsClassList" : baseUrl + "api/get_goods_class_list",

    // 获取PC 幻灯片或者广告
    "homeGetBanner" : baseUrl + "api/get_banner",


    /**
     * @deprecated 聊天接口
     * @author hg <www.qingwuit.com>
     */

    "chatAddFriend" : baseUrl + "chat/add_friend", // 添加为好友
    "chatGetChatFriend" : baseUrl + "chat/get_chat_friend", // 获取聊天好友列表
    "chatGetChatMsg" : baseUrl + "chat/get_chat_msg", // 获取聊天信息
    "chatChatEvent" : baseUrl + "chat/chat_event", // 接收前端信息接口
    "chatReadMsg" : baseUrl + "chat/read_msg", // 清空未读信息
    "chatUpload" : baseUrl + "chat/image", // 图片上次
    
}