import Vue from 'vue'
import Router from 'vue-router'
// import Home from './views/Home.vue'

Vue.use(Router)

export default new Router({
    mode: 'hash',//或history
    routes: [
        // 登录
        {path: '/', name: 'login', component: () => import('./views/Admin/login.vue')},

        // 后台界面
        {
            path: "/Admin/index", name: "admin_index", component: () => import("./views/Admin/index"), children: [

                {path: "/Admin/index", name: "admin_default", component: () => import("./views/Admin/default")}, // 打开默认页面

                // 钩子 Hooks
                {path: "/Admin/hooks/index", name: "hooks_index", component: () => import("./views/Admin/hooks/index")},
                {path: "/Admin/hooks/form", name: "hooks_form", component: () => import("./views/Admin/hooks/form")},

                // 菜单 Menus
                {path: "/Admin/menus/index", name: "menus_index", component: () => import("./views/Admin/menus/index")},
                {path: "/Admin/menus/form", name: "menus_form", component: () => import("./views/Admin/menus/form")},

                // 角色 Roles
                {path: "/Admin/roles/index", name: "roles_index", component: () => import("./views/Admin/roles/index")},
                {path: "/Admin/roles/form", name: "roles_form", component: () => import("./views/Admin/roles/form")},

                // 用户 Users
                {path: "/Admin/users/index", name: "users_index", component: () => import("./views/Admin/users/index")},
                {path: "/Admin/users/form", name: "users_form", component: () => import("./views/Admin/users/form")},

                // 配置页面 Config
                //海报
                {
                    path: "/Admin/config/haib_config",
                    name: "haib_config",
                    component: () => import("./views/Admin/config/haib_config")
                },
                //广告图
                {
                    path: "/Admin/config/guanggt_config",
                    name: "guanggt_config",
                    component: () => import("./views/Admin/config/guanggt_config")
                },
                //家长寄语、学籍中心底部广告图
                {
                    path: "/Admin/config/dbguanggt_config",
                    name: "dbguanggt_config",
                    component: () => import("./views/Admin/config/dbguanggt_config")
                },

                //客服
                {
                    path: "/Admin/config/kef_config",
                    name: "kef_config",
                    component: () => import("./views/Admin/config/kef_config")
                },
                //投票大赛开始截止时间
                {
                    path: "/Admin/config/toupkj_config",
                    name: "toupkj_config",
                    component: () => import("./views/Admin/config/toupkj_config")
                },
                //投票大赛报名开始截止时间
                {
                    path: "/Admin/config/toupbmkj_config",
                    name: "toupbmkj_config",
                    component: () => import("./views/Admin/config/toupbmkj_config")
                },

                //用户协议
                {
                    path: "/Admin/config/yongh_config",
                    name: "yongh_config",
                    component: () => import("./views/Admin/config/yongh_config")
                },
                //入营协议
                {
                    path: "/Admin/config/ruyxy_config",
                    name: "ruyxy_config",
                    component: () => import("./views/Admin/config/ruyxy_config")
                },
                //报道须知
                {
                    path: "/Admin/config/baodxz_config",
                    name: "baodxz_config",
                    component: () => import("./views/Admin/config/baodxz_config")
                },
                //邀请好友规则介绍
                {
                    path: "/Admin/config/yaoqhygzjs_config",
                    name: "yaoqhygzjs_config",
                    component: () => import("./views/Admin/config/yaoqhygzjs_config")
                },


                {
                    path: "/Admin/config/web_config",
                    name: "web_config",
                    component: () => import("./views/Admin/config/web_config")
                },
                {
                    path: "/Admin/config/upload_config",
                    name: "upload_config",
                    component: () => import("./views/Admin/config/upload_config")
                },
                {
                    path: "/Admin/config/map_config",
                    name: "map_config",
                    component: () => import("./views/Admin/config/map_config")
                },
                {
                    path: "/Admin/config/pay_config",
                    name: "pay_config",
                    component: () => import("./views/Admin/config/pay_config")
                },
                {
                    path: "/Admin/config/alisms_config",
                    name: "alisms_config",
                    component: () => import("./views/Admin/config/alisms_config")
                },
                {
                    path: "/Admin/config/wechat_public_config",
                    name: "wechat_public_config",
                    component: () => import("./views/Admin/config/wechat_public_config")
                },
                {
                    path: "/Admin/config/dingjwk_public_config",
                    name: "dingjwk_public_config",
                    component: () => import("./views/Admin/config/dingjwk_public_config")
                },
                {
                    path: "/Admin/config/freight_config",
                    name: "admin_freight_config",
                    component: () => import("./views/Admin/config/freight_config")
                },
                {
                    path: "/Admin/config/oauth_config",
                    name: "admin_oauth_config",
                    component: () => import("./views/Admin/config/oauth_config")
                }, // 第三方登录 PC wechat
                {
                    path: "/Admin/config/distribution",
                    name: "admin_distribution_config",
                    component: () => import("./views/Admin/config/distribution")
                }, // 分销配置
                {
                    path: "/Admin/config/task_time",
                    name: "admin_task_time_config",
                    component: () => import("./views/Admin/config/task_time")
                }, // 任务执行  定时任务控制

                // 短信列表
                {
                    path: "/Admin/config/alisms_list",
                    name: "alisms_list",
                    component: () => import("./views/Admin/config/alisms_list")
                },

                // 商品分类
                {
                    path: "/Admin/goods_class/index",
                    name: "goods_class_index",
                    component: () => import("./views/Admin/goods_class/index")
                },
                {
                    path: "/Admin/goods_class/form",
                    name: "goods_class_form",
                    component: () => import("./views/Admin/goods_class/form")
                },

                // 服务分类
                {
                    path: "/Admin/fuw_class/index",
                    name: "fuw_class_index",
                    component: () => import("./views/Admin/fuw_class/index")
                },
                {
                    path: "/Admin/fuw_class/form",
                    name: "fuw_class_form",
                    component: () => import("./views/Admin/fuw_class/form")
                },


                // 品牌列表
                {
                    path: "/Admin/goods_brand/index",
                    name: "goods_brand_index",
                    component: () => import("./views/Admin/goods_brand/index")
                },
                {
                    path: "/Admin/goods_brand/form",
                    name: "goods_brand_form",
                    component: () => import("./views/Admin/goods_brand/form")
                },

                // 地区管理
                {path: "/Admin/area/index", name: "area_index", component: () => import("./views/Admin/area/index")},
                {path: "/Admin/area/form", name: "area_form", component: () => import("./views/Admin/area/form")},

                // 商品管理
                {
                    path: "/Admin/goods/index",
                    name: "admin_goods_index",
                    component: () => import("./views/Admin/goods/index")
                },
                {
                    path: "/Admin/goods/goods_verify",
                    name: "admin_goods_verify",
                    component: () => import("./views/Admin/goods/goods_verify")
                }, // 待审核页面

                // 服务管理
                {path: "/Admin/fuw/index", name: "admin_fuw_index", component: () => import("./views/Admin/fuw/index")},
                {
                    path: "/Admin/fuw/fuw_verify",
                    name: "admin_fuw_verify",
                    component: () => import("./views/Admin/fuw/fuw_verify")
                }, // 待审核页面


                // 积分商品中心
                {
                    path: "/Admin/integral/index",
                    name: "admin_integral_index",
                    component: () => import("./views/Admin/integral/index")
                },
                {
                    path: "/Admin/integral/add",
                    name: "admin_integral_add",
                    component: () => import("./views/Admin/integral/add")
                }, // 添加积分商品
                {
                    path: "/Admin/integral/edit/:id",
                    name: "admin_integral_edit",
                    component: () => import("./views/Admin/integral/edit")
                }, // 编辑积分商品

                // 积分商城分类
                {
                    path: "/Admin/integral_class/index",
                    name: "admin_integral_class_index",
                    component: () => import("./views/Admin/integral_class/index")
                },
                {
                    path: "/Admin/integral_class/form",
                    name: "admin_integral_class_form",
                    component: () => import("./views/Admin/integral_class/form")
                },

                // 积分商城 订单
                {
                    path: "/Admin/integral_order/index",
                    name: "admin_integral_order_index",
                    component: () => import("./views/Admin/integral_order/index")
                },
                {
                    path: "/Admin/integral_order/info/:id",
                    name: "admin_integral_order_info",
                    component: () => import("./views/Admin/integral_order/info")
                },

                // 订单管理
                {
                    path: "/Admin/order/index",
                    name: "admin_order_index",
                    component: () => import("./views/Admin/order/index")
                },
                {
                    path: "/Admin/order/info/:id",
                    name: "admin_order_info",
                    component: () => import("./views/Admin/order/info")
                },

                // 售后处理
                {
                    path: "/Admin/refund/index",
                    name: "admin_refund_index",
                    component: () => import("./views/Admin/refund/index")
                },

                // 广告位
                {
                    path: "/Admin/adv_position/index",
                    name: "adv_position_index",
                    component: () => import("./views/Admin/adv_position/index")
                },
                {
                    path: "/Admin/adv_position/form",
                    name: "adv_position_form",
                    component: () => import("./views/Admin/adv_position/form")
                },

                // 广告
                {path: "/Admin/adv/index", name: "adv_index", component: () => import("./views/Admin/adv/index")},
                {path: "/Admin/adv/form", name: "adv_form", component: () => import("./views/Admin/adv/form")},

                // 秒杀商品
                {
                    path: "/Admin/seckill/index",
                    name: "seckill_index",
                    component: () => import("./views/Admin/seckill/index")
                },
                {
                    path: "/Admin/seckill/form",
                    name: "seckill_form",
                    component: () => import("./views/Admin/seckill/form")
                },
                {
                    path: "/Admin/seckill/seckill_goods/:sid",
                    name: "admin_seckill_goods_form",
                    component: () => import("./views/Admin/seckill/seckill_goods")
                },

                // 秒杀服务
                {
                    path: "/Admin/seckillfuw/index",
                    name: "seckillfuw_index",
                    component: () => import("./views/Admin/seckillfuw/index")
                },
                {
                    path: "/Admin/seckillfuw/form",
                    name: "seckillfuw_form",
                    component: () => import("./views/Admin/seckillfuw/form")
                },
                {
                    path: "/Admin/seckillfuw/seckill_fuw/:sid",
                    name: "admin_seckill_fuw_form",
                    component: () => import("./views/Admin/seckillfuw/seckill_fuw")
                },


                // 站点协议
                {
                    path: "/Admin/agreement/index",
                    name: "agreement_index",
                    component: () => import("./views/Admin/agreement/index")
                },
                {
                    path: "/Admin/agreement/form",
                    name: "agreement_form",
                    component: () => import("./views/Admin/agreement/form")
                },

                // 店铺 store
                {path: "/Admin/store/index", name: "store_index", component: () => import("./views/Admin/store/index")},
                {
                    path: "/Admin/store/join",
                    name: "store_join_index",
                    component: () => import("./views/Admin/store/join")
                },
                {
                    path: "/Admin/store/error",
                    name: "store_error_index",
                    component: () => import("./views/Admin/store/error")
                },
                {path: "/Admin/store/form", name: "store_form", component: () => import("./views/Admin/store/form")},

                {
                    path: "/Admin/cash/index",
                    name: "admin_cash_index",
                    component: () => import("./views/Admin/cash/index")
                },


                // 常见问题
                {
                    path: "/Admin/comproblem/index",
                    name: "comproblem_index",
                    component: () => import("./views/Admin/comproblem/index")
                },
                {
                    path: "/Admin/comproblem/form",
                    name: "comproblem_form",
                    component: () => import("./views/Admin/comproblem/form")
                },

                // 轮播图
                {path: "/Admin/lunbt/index", name: "lunbt_index", component: () => import("./views/Admin/lunbt/index")},
                {path: "/Admin/lunbt/form", name: "lunbt_form", component: () => import("./views/Admin/lunbt/form")},

                // 课程资料
                {path: "/Admin/keczl/index", name: "keczl_index", component: () => import("./views/Admin/keczl/index")},
                {path: "/Admin/keczl/form", name: "keczl_form", component: () => import("./views/Admin/keczl/form")},

                // 家长寄语轮播图
                {
                    path: "/Admin/jiazjylunbt/index",
                    name: "jiazjylunbt_index",
                    component: () => import("./views/Admin/jiazjylunbt/index")
                },
                {
                    path: "/Admin/jiazjylunbt/form",
                    name: "jiazjylunbt_form",
                    component: () => import("./views/Admin/jiazjylunbt/form")
                },

                // 短视频平台轮播图
                {
                    path: "/Admin/duansplunbt/index",
                    name: "duansplunbt_index",
                    component: () => import("./views/Admin/duansplunbt/index")
                },
                {
                    path: "/Admin/duansplunbt/form",
                    name: "duansplunbt_form",
                    component: () => import("./views/Admin/duansplunbt/form")
                },

                // 投票大赛轮播图
                {
                    path: "/Admin/toupdslunbt/index",
                    name: "toupdslunbt_index",
                    component: () => import("./views/Admin/toupdslunbt/index")
                },
                {
                    path: "/Admin/toupdslunbt/form",
                    name: "toupdslunbt_form",
                    component: () => import("./views/Admin/toupdslunbt/form")
                },

                // 营地商城轮播图
                {
                    path: "/Admin/yingdsclunbt/index",
                    name: "yingdsclunbt_index",
                    component: () => import("./views/Admin/yingdsclunbt/index")
                },
                {
                    path: "/Admin/yingdsclunbt/form",
                    name: "yingdsclunbt_form",
                    component: () => import("./views/Admin/yingdsclunbt/form")
                },

                // 在线报名轮播图
                {
                    path: "/Admin/zaixbmlunbt/index",
                    name: "zaixbmlunbt_index",
                    component: () => import("./views/Admin/zaixbmlunbt/index")
                },
                {
                    path: "/Admin/zaixbmlunbt/form",
                    name: "zaixbmlunbt_form",
                    component: () => import("./views/Admin/zaixbmlunbt/form")
                },


                // 营地商城宣传视频
                {
                    path: "/Admin/yingdscxcsp/index",
                    name: "yingdscxcsp_index",
                    component: () => import("./views/Admin/yingdscxcsp/index")
                },
                {
                    path: "/Admin/yingdscxcsp/form",
                    name: "yingdscxcsp_form",
                    component: () => import("./views/Admin/yingdscxcsp/form")
                },


                // 基地
                {path: "/Admin/jid/index", name: "jid_index", component: () => import("./views/Admin/jid/index")},
                {path: "/Admin/jid/form", name: "jid_form", component: () => import("./views/Admin/jid/form")},

                // 营期
                {path: "/Admin/yingq/index", name: "yingq_index", component: () => import("./views/Admin/yingq/index")},
                {path: "/Admin/yingq/edit", name: "yingq_edit", component: () => import("./views/Admin/yingq/edit")},
                {path: "/Admin/yingq/form", name: "yingq_form", component: () => import("./views/Admin/yingq/form")},

                // 营期期限
                {
                    path: "/Admin/yingqqx/index",
                    name: "yingqqx_index",
                    component: () => import("./views/Admin/yingqqx/index")
                },
                {
                    path: "/Admin/yingqqx/form",
                    name: "yingqqx_form",
                    component: () => import("./views/Admin/yingqqx/form")
                },

                // 班级
                {path: "/Admin/banj/index", name: "banj_index", component: () => import("./views/Admin/banj/index")},
                {path: "/Admin/banj/form", name: "banj_form", component: () => import("./views/Admin/banj/form")},

                // 基地通知
                {path: "/Admin/jidtz/index", name: "jidtz_index", component: () => import("./views/Admin/jidtz/index")},
                {path: "/Admin/jidtz/form", name: "jidtz_form", component: () => import("./views/Admin/jidtz/form")},

                // 课程商城
                {path: "/Admin/kecsc/index", name: "kecsc_index", component: () => import("./views/Admin/kecsc/index")},
                {path: "/Admin/kecsc/edit", name: "kecsc_edit", component: () => import("./views/Admin/kecsc/edit")},
                {path: "/Admin/kecsc/form", name: "kecsc_form", component: () => import("./views/Admin/kecsc/form")},

                // 课程商城评价
                {
                    path: "/Admin/kecscpj/index",
                    name: "kecscpj_index",
                    component: () => import("./views/Admin/kecscpj/index")
                },
                {
                    path: "/Admin/kecscpj/form",
                    name: "kecscpj_form",
                    component: () => import("./views/Admin/kecscpj/form")
                },

                // 相册商城
                {
                    path: "/Admin/xiangcsc/index",
                    name: "xiangcsc_index",
                    component: () => import("./views/Admin/xiangcsc/index")
                },
                {
                    path: "/Admin/xiangcsc/edit",
                    name: "xiangcsc_edit",
                    component: () => import("./views/Admin/xiangcsc/edit")
                },
                {
                    path: "/Admin/xiangcsc/form",
                    name: "xiangcsc_form",
                    component: () => import("./views/Admin/xiangcsc/form")
                },


                // 相册商城评价
                {
                    path: "/Admin/xiangcscpj/index",
                    name: "xiangcscpj_index",
                    component: () => import("./views/Admin/xiangcscpj/index")
                },
                {
                    path: "/Admin/xiangcscpj/form",
                    name: "xiangcscpj_form",
                    component: () => import("./views/Admin/xiangcscpj/form")
                },


                // 相册商城订单
                {
                    path: "/Admin/xiangcscdd/index",
                    name: "xiangcscdd_index",
                    component: () => import("./views/Admin/xiangcscdd/index")
                },
                {
                    path: "/Admin/xiangcscdd/form",
                    name: "xiangcscdd_form",
                    component: () => import("./views/Admin/xiangcscdd/form")
                },
                {
                    path: "/Admin/xiangcscdd/info/:id",
                    name: "xiangcscdd_info",
                    component: () => import("./views/Admin/xiangcscdd/info")
                },

                // 课程商城订单
                {
                    path: "/Admin/kecscdd/index",
                    name: "kecscdd_index",
                    component: () => import("./views/Admin/kecscdd/index")
                },
                {
                    path: "/Admin/kecscdd/form",
                    name: "kecscdd_form",
                    component: () => import("./views/Admin/kecscdd/form")
                },
                {
                    path: "/Admin/kecscdd/info/:id",
                    name: "kecscdd_info",
                    component: () => import("./views/Admin/kecscdd/info")
                },

                // 学员列表
                {
                    path: "/Admin/xueyxj/index",
                    name: "xueyxj_index",
                    component: () => import("./views/Admin/xueyxj/index")
                },
                {path: "/Admin/xueyxj/form", name: "xueyxj_form", component: () => import("./views/Admin/xueyxj/form")},
                {
                    path: "/Admin/xueyxj/forms",
                    name: "xueyxj_forms",
                    component: () => import("./views/Admin/xueyxj/forms")
                },


                // 提现列表
                {path: "/Admin/tix/index", name: "tix_index", component: () => import("./views/Admin/tix/index")},
                {path: "/Admin/tix/form", name: "tix_form", component: () => import("./views/Admin/tix/form")},


                //学员推送消息通知时间设置
                {
                    path: "/Admin/config/xxtssz",
                    name: "xxtssz_config",
                    component: () => import("./views/Admin/config/xxtssz_config")
                },

                // 学员消息推送列表
                {path: "/Admin/xxlb/index", name: "xxlb_index", component: () => import("./views/Admin/xxlb/index")},
                {path: "/Admin/xxlb/form", name: "xxlb_form", component: () => import("./views/Admin/xxlb/form")},


                // 私人定制
                {path: "/Admin/sirdz/index", name: "sirdz_index", component: () => import("./views/Admin/sirdz/index")},
                {path: "/Admin/sirdz/form", name: "sirdz_form", component: () => import("./views/Admin/sirdz/form")},
                {path: "/Admin/sirdz/edit", name: "sirdz_edit", component: () => import("./views/Admin/sirdz/edit")},

                // 私人定制订单
                {
                    path: "/Admin/sirdzdd/index",
                    name: "sirdzdd_index",
                    component: () => import("./views/Admin/sirdzdd/index")
                },
                {
                    path: "/Admin/sirdzdd/form",
                    name: "sirdzdd_form",
                    component: () => import("./views/Admin/sirdzdd/form")
                },


                // 照片直播首页
                {
                    path: "/Admin/zhaopzbsy/index",
                    name: "zhaopzbsy_index",
                    component: () => import("./views/Admin/zhaopzbsy/index")
                },
                {
                    path: "/Admin/zhaopzbsy/form",
                    name: "zhaopzbsy_form",
                    component: () => import("./views/Admin/zhaopzbsy/form")
                },

                // 图片直播
                {path: "/Admin/tupzb/index", name: "tupzb_index", component: () => import("./views/Admin/tupzb/index")},
                {path: "/Admin/tupzb/form", name: "tupzb_form", component: () => import("./views/Admin/tupzb/form")},
                {path: "/Admin/tupzb/edit", name: "tupzb_edit", component: () => import("./views/Admin/tupzb/edit")},

                // 照片直播热门
                {
                    path: "/Admin/zhaopzbrm/index",
                    name: "zhaopzbrm_index",
                    component: () => import("./views/Admin/zhaopzbrm/index")
                },
                {
                    path: "/Admin/zhaopzbrm/form",
                    name: "zhaopzbrm_form",
                    component: () => import("./views/Admin/zhaopzbrm/form")
                },
                {
                    path: "/Admin/zhaopzbrm/edit",
                    name: "zhaopzbrm_edit",
                    component: () => import("./views/Admin/zhaopzbrm/edit")
                },

                // 照片直播视频
                {
                    path: "/Admin/zhaopzbsp/index",
                    name: "zhaopzbsp_index",
                    component: () => import("./views/Admin/zhaopzbsp/index")
                },
                {
                    path: "/Admin/zhaopzbsp/form",
                    name: "zhaopzbsp_form",
                    component: () => import("./views/Admin/zhaopzbsp/form")
                },
                {
                    path: "/Admin/zhaopzbsp/edit",
                    name: "zhaopzbsp_edit",
                    component: () => import("./views/Admin/zhaopzbsp/edit")
                },


                // 招生老师
                {path: "/Admin/zsls/index", name: "zsls_index", component: () => import("./views/Admin/zsls/index")},
                {path: "/Admin/zsls/form", name: "zsls_form", component: () => import("./views/Admin/zsls/form")},

                // 普通会员
                {path: "/Admin/puthy/index", name: "puthy_index", component: () => import("./views/Admin/puthy/index")},

                // 一级代理
                {path: "/Admin/yijdl/index", name: "yijdl_index", component: () => import("./views/Admin/yijdl/index")},

                // 二级代理
                {path: "/Admin/erjdl/index", name: "erjdl_index", component: () => import("./views/Admin/erjdl/index")},



                // 线下订单老师
                {
                    path: "/Admin/xianxls/index",
                    name: "xianxls_index",
                    component: () => import("./views/Admin/xianxls/index")
                },
                {
                    path: "/Admin/xianxls/form",
                    name: "xianxls_form",
                    component: () => import("./views/Admin/xianxls/form")
                },


                // 公众号关注列表
                {path: "/Admin/gzhgz/index", name: "gzhgz_index", component: () => import("./views/Admin/gzhgz/index")},
                {path: "/Admin/gzhgz/form", name: "gzhgz_form", component: () => import("./views/Admin/gzhgz/form")},


                // 线索列表
                {
                    path: "/Admin/xianslb/index",
                    name: "xianslb_index",
                    component: () => import("./views/Admin/xianslb/index")
                },
                {
                    path: "/Admin/xianslb/form",
                    name: "xianslb_form",
                    component: () => import("./views/Admin/xianslb/form")
                },


                // 招生老师推送消息
                {path: "/Admin/xxts/index", name: "xxts_index", component: () => import("./views/Admin/xxts/index")},
                {path: "/Admin/xxts/form", name: "xxts_form", component: () => import("./views/Admin/xxts/form")},

                // 学员推送消息
                {
                    path: "/Admin/xyxjxxts/index",
                    name: "xyxjxxts_index",
                    component: () => import("./views/Admin/xyxjxxts/index")
                },
                {
                    path: "/Admin/xyxjxxts/form",
                    name: "xyxjxxts_form",
                    component: () => import("./views/Admin/xyxjxxts/form")
                },


                // 入营通知书
                {
                    path: "/Admin/tongzs/index",
                    name: "tongzs_index",
                    component: () => import("./views/Admin/tongzs/index")
                },
                {path: "/Admin/tongzs/form", name: "tongzs_form", component: () => import("./views/Admin/tongzs/form")},

                // 在线报名
                {
                    path: "/Admin/zaixbm/index",
                    name: "zaixbm_index",
                    component: () => import("./views/Admin/zaixbm/index")
                },
                {path: "/Admin/zaixbm/form", name: "zaixbm_form", component: () => import("./views/Admin/zaixbm/form")},
                {
                    path: "/Admin/zaixbm/info/:id",
                    name: "admin_zaixbm_info",
                    component: () => import("./views/Admin/zaixbm/info")
                },

                // 投票大赛
                {
                    path: "/Admin/toupds/baomxs",
                    name: "toupds_index",
                    component: () => import("./views/Admin/toupds/index")
                },
                {path: "/Admin/toupds/form", name: "toupds_form", component: () => import("./views/Admin/toupds/form")},

                // 报名选手
                {
                    path: "/Admin/toupds/index",
                    name: "xuansbm_index",
                    component: () => import("./views/Admin/xuansbm/index")
                },
                {
                    path: "/Admin/toupds/form",
                    name: "xuansbm_form",
                    component: () => import("./views/Admin/xuansbm/form")
                },

                // 家长寄语
                {
                    path: "/Admin/jiazjy/index",
                    name: "jiazjy_index",
                    component: () => import("./views/Admin/jiazjy/index")
                },
                {path: "/Admin/jiazjy/form", name: "jiazjy_form", component: () => import("./views/Admin/jiazjy/form")},


                // 公告文字
                {path: "/Admin/gongg/index", name: "gongg_index", component: () => import("./views/Admin/gongg/index")},
                {path: "/Admin/gongg/form", name: "gongg_form", component: () => import("./views/Admin/gongg/form")},


                // 短视频营期
                {
                    path: "/Admin/duanspyq/index",
                    name: "duanspyq_index",
                    component: () => import("./views/Admin/duanspyq/index")
                },
                {
                    path: "/Admin/duanspyq/form",
                    name: "duanspyq_form",
                    component: () => import("./views/Admin/duanspyq/form")
                },

                // 短视频列表
                {
                    path: "/Admin/duansplb/index",
                    name: "duansplb_index",
                    component: () => import("./views/Admin/duansplb/index")
                },
                {
                    path: "/Admin/duansplb/form",
                    name: "duansplb_form",
                    component: () => import("./views/Admin/duansplb/form")
                },

                // 音乐列表
                {
                    path: "/Admin/yinylb/index",
                    name: "duansplb_index",
                    component: () => import("./views/Admin/yinylb/index")
                },
                {
                    path: "/Admin/yinylb/form",
                    name: "duansplb_form",
                    component: () => import("./views/Admin/yinylb/form")
                },


                // 尾款列表
                {path: "/Admin/weik/index", name: "weik_index", component: () => import("./views/Admin/weik/index")},
                {path: "/Admin/weik/edit", name: "weik_edit", component: () => import("./views/Admin/weik/edit")},
                {path: "/Admin/weik/form", name: "weik_form", component: () => import("./views/Admin/weik/form")},


                /*************************美甲纹绣*********************/

                // 门店管理
                {path: "/Admin/mend/index", name: "mend_index", component: () => import("./views/Admin/mend/index")},
                {path: "/Admin/mend/form", name: "mend_form", component: () => import("./views/Admin/mend/form")},

                // 系统消息
                {path: "/Admin/xitxx/index", name: "xitxx_index", component: () => import("./views/Admin/xitxx/index")},
                {path: "/Admin/xitxx/form", name: "xitxx_form", component: () => import("./views/Admin/xitxx/form")},

                // 活动消息
                {
                    path: "/Admin/huodxx/index",
                    name: "huodxx_index",
                    component: () => import("./views/Admin/huodxx/index")
                },
                {path: "/Admin/huodxx/form", name: "huodxx_form", component: () => import("./views/Admin/huodxx/form")},

                // 意见反馈
                {path: "/Admin/yijfk/index", name: "yijfk_index", component: () => import("./views/Admin/yijfk/index")},
                {path: "/Admin/yijfk/form", name: "yijfk_form", component: () => import("./views/Admin/yijfk/form")},
                // 意见反馈类型
                {
                    path: "/Admin/yijfklx/index",
                    name: "yijfklx_index",
                    component: () => import("./views/Admin/yijfklx/index")
                },
                {
                    path: "/Admin/yijfklx/form",
                    name: "yijfklx_form",
                    component: () => import("./views/Admin/yijfklx/form")
                },


                // 常见问题
                {
                    path: "/Admin/changjwt/index",
                    name: "changjwt_index",
                    component: () => import("./views/Admin/changjwt/index")
                },
                {
                    path: "/Admin/changjwt/form",
                    name: "changjwt_form",
                    component: () => import("./views/Admin/changjwt/form")
                },
                // 优惠问题
                {
                    path: "/Admin/youhwt/index",
                    name: "youhwt_index",
                    component: () => import("./views/Admin/youhwt/index")
                },
                {path: "/Admin/youhwt/form", name: "youhwt_form", component: () => import("./views/Admin/youhwt/form")},
                // 秒杀问题
                {
                    path: "/Admin/miaoswt/index",
                    name: "miaoswt_index",
                    component: () => import("./views/Admin/miaoswt/index")
                },
                {
                    path: "/Admin/miaoswt/form",
                    name: "miaoswt_form",
                    component: () => import("./views/Admin/miaoswt/form")
                },
                // 支付问题
                {
                    path: "/Admin/zhifwt/index",
                    name: "zhifwt_index",
                    component: () => import("./views/Admin/zhifwt/index")
                },
                {path: "/Admin/zhifwt/form", name: "zhifwt_form", component: () => import("./views/Admin/zhifwt/form")},
                // 活动问题
                {
                    path: "/Admin/huodwt/index",
                    name: "huodwt_index",
                    component: () => import("./views/Admin/huodwt/index")
                },
                {path: "/Admin/huodwt/form", name: "huodwt_form", component: () => import("./views/Admin/huodwt/form")},
                // 其它问题
                {path: "/Admin/qitwt/index", name: "qitwt_index", component: () => import("./views/Admin/qitwt/index")},
                {path: "/Admin/qitwt/form", name: "qitwt_form", component: () => import("./views/Admin/qitwt/form")},


                // 线下订单
                {
                    path: "/Admin/xianxdd/index",
                    name: "xianxdd_index",
                    component: () => import("./views/Admin/xianxdd/index")
                },
                {
                    path: "/Admin/xianxdd/form",
                    name: "xianxdd_form",
                    component: () => import("./views/Admin/xianxdd/form")
                },


                // 线下订单补色
                {
                    path: "/Admin/xianxddbs/index",
                    name: "xianxddbs_index",
                    component: () => import("./views/Admin/xianxddbs/index")
                },
                {
                    path: "/Admin/xianxddbs/form",
                    name: "xianxddbs_form",
                    component: () => import("./views/Admin/xianxddbs/form")
                },


                // 线下订单套餐
                {
                    path: "/Admin/xianxddtc/index",
                    name: "xianxddtc_index",
                    component: () => import("./views/Admin/xianxddtc/index")
                },
                {
                    path: "/Admin/xianxddtc/form",
                    name: "xianxddtc_form",
                    component: () => import("./views/Admin/xianxddtc/form")
                },

                // 线下订单消费来源
                {
                    path: "/Admin/xianxddxfly/index",
                    name: "xianxddxfly_index",
                    component: () => import("./views/Admin/xianxddxfly/index")
                },
                {
                    path: "/Admin/xianxddxfly/form",
                    name: "xianxddxfly_form",
                    component: () => import("./views/Admin/xianxddxfly/form")
                },

                // 线下订单消费方式
                {
                    path: "/Admin/xianxddxffs/index",
                    name: "xianxddxffs_index",
                    component: () => import("./views/Admin/xianxddxffs/index")
                },
                {
                    path: "/Admin/xianxddxffs/form",
                    name: "xianxddxffs_form",
                    component: () => import("./views/Admin/xianxddxffs/form")
                },


                // 评价中心
                {path: "/Admin/pingj/index", name: "pingj_index", component: () => import("./views/Admin/pingj/index")},
                {path: "/Admin/pingj/form", name: "pingj_form", component: () => import("./views/Admin/pingj/form")},
                // 满多少可用到时优惠券
                {
                    path: "/Admin/mankyyhq/index",
                    name: "mankyyhq_index",
                    component: () => import("./views/Admin/mankyyhq/index")
                },
                {
                    path: "/Admin/mankyyhq/form",
                    name: "mankyyhq_form",
                    component: () => import("./views/Admin/mankyyhq/form")
                },
                // 新人到时优惠券
                {
                    path: "/Admin/xinryhq/index",
                    name: "xinryhq_index",
                    component: () => import("./views/Admin/xinryhq/index")
                },
                {
                    path: "/Admin/xinryhq/form",
                    name: "xinryhq_form",
                    component: () => import("./views/Admin/xinryhq/form")
                },
                // 分享人到时优惠券
                {
                    path: "/Admin/fenxyhq/index",
                    name: "fenxyhq_index",
                    component: () => import("./views/Admin/fenxyhq/index")
                },
                {
                    path: "/Admin/fenxyhq/form",
                    name: "fenxyhq_form",
                    component: () => import("./views/Admin/fenxyhq/form")
                },


                // 拼团订单
                {
                    path: "/Admin/groupbuy_order/index",
                    name: "admin_groupbuy_order_index",
                    component: () => import("./views/Admin/groupbuy_order/index")
                },
                {
                    path: "/Admin/groupbuy_order/groupbuy_user/:gb_id",
                    name: "admin_groupbuy_user_index",
                    component: () => import("./views/Admin/groupbuy_order/groupbuy_user")
                },

                // 商品规格属性
                {
                    path: "/Admin/attr_spec/index",
                    name: "attr_spec_index",
                    component: () => import("./views/Admin/attr_spec/index")
                },
                {
                    path: "/Admin/attr_spec/form",
                    name: "attr_spec_form",
                    component: () => import("./views/Admin/attr_spec/form")
                },


                // 服务套餐属性
                {
                    path: "/Admin/fuwattr_spec/index",
                    name: "fuwattr_spec_index",
                    component: () => import("./views/Admin/fuwattr_spec/index")
                },
                {
                    path: "/Admin/fuwattr_spec/form",
                    name: "fuwattr_spec_form",
                    component: () => import("./views/Admin/fuwattr_spec/form")
                },

                // 商家自定义分类
                {
                    path: "/Admin/store_goods_class/index",
                    name: "store_goods_class_index",
                    component: () => import("./views/Admin/store_goods_class/index")
                },
                {
                    path: "/Admin/store_goods_class/form",
                    name: "store_goods_class_form",
                    component: () => import("./views/Admin/store_goods_class/form")
                },


                // 商品中心
                {
                    path: "/Admin/goods/index",
                    name: "seller_goods_index",
                    component: () => import("./views/Admin/goods/index")
                },
                {
                    path: "/Admin/goods/chose_class",
                    name: "seller_goods_chose_class",
                    component: () => import("./views/Admin/goods/chose_class")
                }, // 添加商品先选择分类
                {
                    path: "/Admin/goods/add/:id",
                    name: "seller_goods_add",
                    component: () => import("./views/Admin/goods/add")
                }, // 添加商品
                {
                    path: "/Admin/goods/edit/:id",
                    name: "seller_goods_edit",
                    component: () => import("./views/Admin/goods/edit")
                }, // 编辑商品


                // 服务中心
                {
                    path: "/Admin/fuw/index",
                    name: "seller_fuw_index",
                    component: () => import("./views/Admin/fuw/index")
                },
                {
                    path: "/Admin/fuw/chose_class",
                    name: "seller_fuw_chose_class",
                    component: () => import("./views/Admin/fuw/chose_class")
                }, // 添加商品先选择分类
                {path: "/Admin/fuw/add/:id", name: "seller_fuws_add", component: () => import("./views/Admin/fuw/add")}, // 添加商品
                {
                    path: "/Admin/fuw/edit/:id",
                    name: "seller_fuw_edit",
                    component: () => import("./views/Admin/fuw/edit")
                }, // 编辑商品


                /*************************美甲纹绣*********************/


            ]
        },


        // 商家登录
        {path: '/Seller/login', name: 'seller_login', component: () => import('./views/Seller/login.vue')},

        // 后台界面
        {
            path: "/Seller/index", name: "seller_index", component: () => import("./views/Seller/index"), children: [

                {path: "/Seller/index", name: "seller_default", component: () => import("./views/Seller/default")}, // 打开默认页面


                // 规格属性
                {
                    path: "/Seller/attr_spec/index",
                    name: "attr_spec_index",
                    component: () => import("./views/Seller/attr_spec/index")
                },
                {
                    path: "/Seller/attr_spec/form",
                    name: "attr_spec_form",
                    component: () => import("./views/Seller/attr_spec/form")
                },

                // 商家自定义分类
                {
                    path: "/Seller/store_goods_class/index",
                    name: "store_goods_class_index",
                    component: () => import("./views/Seller/store_goods_class/index")
                },
                {
                    path: "/Seller/store_goods_class/form",
                    name: "store_goods_class_form",
                    component: () => import("./views/Seller/store_goods_class/form")
                },

                // 物流 快递模版
                {
                    path: "/Seller/freight_template/index",
                    name: "freight_template_index",
                    component: () => import("./views/Seller/freight_template/index")
                },
                {
                    path: "/Seller/freight_template/form",
                    name: "freight_template_form",
                    component: () => import("./views/Seller/freight_template/form")
                },

                // 店铺设置
                {
                    path: "/Seller/store/setting",
                    name: "store_setting",
                    component: () => import("./views/Seller/store/setting")
                },
                {
                    path: "/Seller/free_freight/index",
                    name: "free_freight",
                    component: () => import("./views/Seller/store/free_freight")
                }, // 免邮包邮设置
                {
                    path: "/Seller/store/after_sale_content",
                    name: "seller_after_sale_content",
                    component: () => import("./views/Seller/store/after_sale_content")
                }, // 免邮包邮设置

                // 订单管理
                {
                    path: "/Seller/order/index",
                    name: "seller_order_index",
                    component: () => import("./views/Seller/order/index")
                },
                {
                    path: "/Seller/order/info/:id",
                    name: "seller_order_info",
                    component: () => import("./views/Seller/order/info")
                },
                {
                    path: "/Seller/refund/index",
                    name: "seller_refund_index",
                    component: () => import("./views/Seller/refund/index")
                }, // 售后订单


                // 拼团订单
                {
                    path: "/Seller/groupbuy_order/index",
                    name: "seller_groupbuy_order_index",
                    component: () => import("./views/Seller/groupbuy_order/index")
                },
                {
                    path: "/Seller/groupbuy_order/groupbuy_user/:gb_id",
                    name: "seller_groupbuy_user_index",
                    component: () => import("./views/Seller/groupbuy_order/groupbuy_user")
                },

                // 秒杀
                {
                    path: "/Seller/seckill/index",
                    name: "seller_seckill_index",
                    component: () => import("./views/Seller/seckill/index")
                },
                {
                    path: "/Seller/seckill/form",
                    name: "seller_seckill_form",
                    component: () => import("./views/Seller/seckill/form")
                },
                {
                    path: "/Seller/seckill/seckill_goods/:sid",
                    name: "seller_seckill_goods_form",
                    component: () => import("./views/Seller/seckill/seckill_goods")
                },

            ]
        },


        // PC端界面
        /*{path: '/',name: 'home',component: () => import('./views/Home/index.vue')},*/

        {path: '/user/login', name: 'user_login', component: () => import('./views/Home/login.vue')},
        {path: '/user/register', name: 'user_register', component: () => import('./views/Home/register.vue')},
        {
            path: '/user/forget_password',
            name: 'user_forget_password',
            component: () => import('./views/Home/forget_password.vue')
        }, // 忘记密码


        // 入驻
        {path: '/store/join', name: 'store_join', component: () => import('./views/Home/store/join.vue')},
        {path: '/store/join_1', name: 'store_join_1', component: () => import('./views/Home/store/join_1.vue')},
        {path: '/store/join_2', name: 'store_join_2', component: () => import('./views/Home/store/join_2.vue')},
        {path: '/store/join_3', name: 'store_join_3', component: () => import('./views/Home/store/join_3.vue')}, // 审核通过
        {path: '/store/join_4', name: 'store_join_4', component: () => import('./views/Home/store/join_4.vue')}, // 审核中

        // 店铺首页
        {path: '/store/:id', name: 'home_store_index', component: () => import('./views/Home/store/index.vue')},
        {
            path: '/store/:id/class/:class_id',
            name: 'home_store_goods_list',
            component: () => import('./views/Home/store/store_goods_list.vue')
        },

        // 产品列表
        {path: '/goods/params/:info', name: 'goods_index', component: () => import('./views/Home/goods/index.vue')},

        // 秒杀列表
        {path: '/goods/seckill', name: 'goods_seckill', component: () => import('./views/Home/goods/seckill.vue')},

        // 产品详情
        {
            path: '/goods/info/:goods_id',
            name: 'home_goods_info',
            component: () => import('./views/Home/goods/info.vue')
        },

        // 用户中心
        {
            path: '/user/index', name: 'user_index', component: () => import('./views/Home/user/index.vue'), children: [
                {path: '/user/index', name: 'user_default', component: () => import('./views/Home/user/default.vue')},
                {path: '/user/address', name: 'user_address', component: () => import('./views/Home/user/address.vue')},
                {path: '/user/order', name: 'user_order', component: () => import('./views/Home/user/order.vue')},
                {
                    path: '/user/user_info',
                    name: 'home_user_info',
                    component: () => import('./views/Home/user/user_info.vue')
                },
                {path: '/user/fav', name: 'home_fav', component: () => import('./views/Home/user/fav.vue')}, // 收藏关注
                {
                    path: '/user/fav_store',
                    name: 'home_fav_store',
                    component: () => import('./views/Home/user/fav_store.vue')
                }, // 收藏关注
                {
                    path: '/user/get_money_log/money',
                    name: 'home_get_money_log',
                    component: () => import('./views/Home/user/log.vue')
                }, // 资金变更日志
                {
                    path: '/user/get_money_log/freeze_money',
                    name: 'home_get_money_log1',
                    component: () => import('./views/Home/user/log_1.vue')
                }, // 资金变更日志
                {
                    path: '/user/get_money_log/integral',
                    name: 'home_get_money_log2',
                    component: () => import('./views/Home/user/log_2.vue')
                }, // 资金变更日志
                {path: '/user/safe', name: 'home_user_safe', component: () => import('./views/Home/user/safe.vue')}, // 账号安全

                {
                    path: '/user/safe/password',
                    name: 'home_user_safe_password',
                    component: () => import('./views/Home/user//safe/password.vue')
                }, // 密码修改
                {
                    path: '/user/safe/pay_password',
                    name: 'home_user_safe_pay_password',
                    component: () => import('./views/Home/user//safe/pay_password.vue')
                }, // 支付密码修改

                {
                    path: '/user/user_bind',
                    name: 'home_user_bind',
                    component: () => import('./views/Home/user/user_bind.vue')
                }, // 账号绑定
                {
                    path: '/user/safe/card',
                    name: 'home_user_card',
                    component: () => import('./views/Home/user/safe/card.vue')
                }, // 身份认证

                // 评论列表
                {
                    path: '/user/comment/add/:order_id',
                    name: 'home_comment_add',
                    component: () => import('./views/Home/user/comment/add.vue')
                }, // 订单添加评论
                {
                    path: '/user/comment/index',
                    name: 'home_comment_index',
                    component: () => import('./views/Home/user/comment/index.vue')
                }, // 订单添加评论

                // 分销信息
                {
                    path: '/user/inviter/inviter_info',
                    name: 'home_inviter_info',
                    component: () => import('./views/Home/user/inviter/inviter_info.vue')
                },
                {
                    path: '/user/inviter/inviter_member',
                    name: 'home_inviter_member',
                    component: () => import('./views/Home/user/inviter/inviter_member.vue')
                },
                {
                    path: '/user/inviter/inviter_money',
                    name: 'home_inviter_money',
                    component: () => import('./views/Home/user/inviter/inviter_money.vue')
                },

                // 积分订单
                {
                    path: '/user/integral_order',
                    name: 'integral_order',
                    component: () => import('./views/Home/user/integral_order.vue')
                },

                // 提现列表页面
                {
                    path: '/user/cash/index',
                    name: 'home_cash_index',
                    component: () => import('./views/Home/user/cash/index.vue')
                },
            ]
        },


        // 购物车
        {path: '/cart/index', name: 'cart_index', component: () => import('./views/Home/cart/index.vue')},

        // 生成订单页面  或者选择物流地址
        {
            path: '/order/create_order/:type/:info',
            name: 'create_order_index',
            component: () => import('./views/Home/order/create_order.vue')
        },
        {
            path: '/order/chose_pay/:order_no/:type/:info',
            name: 'home_chose_pay',
            component: () => import('./views/Home/order/chose_pay.vue')
        },
        {
            path: '/order/pay_success',
            name: 'home_pay_success',
            component: () => import('./views/Home/order/pay_success.vue')
        },

        // 积分商城
        {
            path: '/integral/index',
            name: 'home_integral_index',
            component: () => import('./views/Home/integral/index.vue')
        },
        {
            path: '/integral/goods/index/:info',
            name: 'home_integral_goods_index',
            component: () => import('./views/Home/integral/goods/index.vue')
        },
        {
            path: '/integral/goods/info/:id',
            name: 'home_integral_goods_info',
            component: () => import('./views/Home/integral/goods/info.vue')
        },

        // 积分商城订单
        {
            path: '/integral/order/create_order/:info',
            name: 'home_integral_order_create_order',
            component: () => import('./views/Home/integral/order/create_order.vue')
        },
        {
            path: '/integral/order/pay_success',
            name: 'home_integral_order_pay_success',
            component: () => import('./views/Home/integral/order/pay_success.vue')
        },   // 积分兑换成功

        // 拼团商品列表页面
        {
            path: '/groupbuy/list/:info',
            name: 'home_groupbuy_list',
            component: () => import('./views/Home/goods/groupbuy.vue')
        },


        // {
        //   path: '/',
        //   name: 'home',
        //   component: Home
        // },
        // {
        //   path: '/about',
        //   name: 'about',
        //   // route level code-splitting
        //   // this generates a separate chunk (about.[hash].js) for this route
        //   // which is lazy-loaded when the route is visited.
        //   component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
        // }
    ]
})
